.activation-codes {
  .info-bar {
    height: 64px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .page {
    background-color: #424242;
    display: flex;
    flex-direction: column;
    padding: 24px;

    .table-top {
      display: flex;
      flex-direction: row;
    }

    .separator {
      text-align: right;
    }

    .instructions{
      margin-bottom: 12px;
      display: flex;
      flex-direction: column;
      align-items: flex-start; 
      flex: 1;

      .code {
        font-family: monospace;
        background-color: #303030;
        padding: 8px 12px;
        flex-grow: 0;
      }
    }
  }

  textarea {
    border: none;
    overflow: auto;
    outline: none;
    min-height: 40vh;

    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    flex: 1;

    resize: none; /*remove the resize handle on the bottom right*/

    background-color: #5e5e5e;
    border: 1px solid #333;
    color: #fff;
    font-family: monospace;
    font-size: 15px;height: 70vh;
  }

  .line {
    display: flex;
    flex-direction: row;
    padding: 4px 4px;

    &:nth-child(odd) {
      background-color: #4f4f4f;
    }

    &.saved {
      background-color: #294e29;

      &:nth-child(odd) {
        background-color: #305c30;
      }
    }

    &.error {
      background-color: #562c2c;
      &:nth-child(odd) {
        background-color: #562c2c;
      }
    }


    &.line-header {
      font-weight: bold;
      background-color: #303030;
    }


    .line-item {
      flex-basis: 0;
      flex-grow: 1;
      
    }
    .line-item-small {
      flex-basis: 0;
      flex-grow: 0.5;
    }
  }
}
